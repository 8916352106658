* {
  font-family: "Poppins", sans-serif !important;
}

.text-subtitle {
  color: #4a7389;
}
.text-error {
  color: #d32f2f;
}
.choose-file-input {
  border-radius:1px solid #d32f2f;
}
.each_input {
  background: #f7f7f7;
  padding: 8px 16px;
  border-radius: 8px;
  text-align: start;
}
.each_input > span {
  font-size: 13px;
  font-weight: 600;
}
.each_input_modal {
  background: #f7f7f7;
  padding: 2px 6px 2px 10px;
  border-radius: 4px;
  text-align: start;
}
.each_input_modal > span {
  font-size: 13px;
  font-weight: 500;
}
.margin {
  margin-top: 15px;
}
.btn_edit {
  position: fixed !important;
  bottom: 45px;
  right: 45px;
}
.icon_edit {
  font-size: 34px !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 391px) {
  .btn_edit {
    bottom: 10px;
    right: 10px;
  }
  .icon_edit {
    font-size: 32px !important;
  }
}
@media (min-width: 392px) and (max-width: 776px) {
  .btn_edit {
    bottom: 46px;
    right: 40px;
  }
  .icon_edit {
    font-size: 35px !important;
  }
}
@media (min-width: 777px) and (max-width: 992px) {
  .btn_edit {
    bottom: 52px;
    right: 50px;
  }
  .icon_edit {
    font-size: 40px !important;
  }
}
