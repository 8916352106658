.form-control {
  border: 0.1px solid rgba(0, 0, 0, 0.3) !important;
}
.form-control-error {
  border: 0.1px solid #d32f2f !important;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  appearance: none;
  border-radius: 0.25rem;
  min-height: auto;
  transition: all 0.1s linear;
  padding: 3.5px;
}
.form-control-error[type="file"] {
  overflow: hidden;
}
.form-control-error[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
