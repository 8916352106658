.whole {
  transition: 0.3s;
}
.whole:hover {
  background-color: #f5f5f5;
}
.whole-wrapper {
  display: flex;
  align-items: start;
  gap: 4px;
}

.whole-wrapper-title-bold {
  font-size: 17px;
  font-weight: 700 !important;
}
.whole-wrapper-title {
  font-size: 17px;
}
.whole-wrapper-title-status {
  font-size: 16px;
  color: #1769aa;
  font-weight: 600 !important;
}
