.title-users {
  font-weight: 500 !important;
}
.items-card {
  cursor: pointer;
  transition: 0.3s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.items-card:hover {
  cursor: pointer;
  transform: scale(1.03);
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
}
